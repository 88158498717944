<template>
  <v-dialog
    v-if="$admin.can('category-update')"
    v-model="isVisibleDialog"
    persistent
    class="edit-dialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="$admin.can('category-create')"
        color="primary"
        v-bind="attrs"
        v-on="on"
      >
        {{ $t("add_new_category") }}
      </v-btn>
    </template>
    <v-form
      ref="form"
      v-model="is_valid_form"
      lazy-validation
    >
      <v-card>
        <v-card-title class="pb-4">
          <span class="text-h3">{{ $t("category") }}</span>
        </v-card-title>
        <v-card-text class="">
          <v-container>
            <v-row v-if="formErrors && !(formErrors.length == 0)">
              <v-col cols="12">
                <v-alert
                  type="error"
                  dense
                  outlined
                  class="pa-2 mb-0"
                >
                  <ul>
                    <li
                      v-for="(err, key) in formErrors"
                      :key="key"
                    >
                      {{ key }} : {{ err[0] }}
                    </li>
                  </ul>
                </v-alert>
              </v-col>
            </v-row>
            <v-row class="justify-center">
              <v-col
                cols="12"
                md="4"
                v-if="isNew"
              >
                <v-select
                  dense
                  hide-details
                  outlined
                  v-model="category.level"
                  :items="categoriesType"
                  :label="$t('type_*')"
                  item-value="level"
                  item-text="name"
                  :rules="[rules.required]"
                ></v-select>
              </v-col>

              <v-col
                cols="12"
                md="4"
                v-if="category.level == 3 && isNew"
              >
                <v-autocomplete
                  clearable
                  dense
                  hide-details
                  outlined
                  v-model="category.parent_id"
                  :items="rootCategories"
                  item-value="id"
                  item-text="name"
                  :label="$t('category_parent')"
                  @focus="getSpecialCategories"
                ></v-autocomplete>
              </v-col>

              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  dense
                  auto-select-first
                  hide-details
                  outlined
                  :label="$t('name_*')"
                  v-model="category.name"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-file-input
                  dense
                  outlined
                  @change="onFileChanged($event)"
                  accept="image/*"
                  type="file"
                  label="image"
                  prepend-icon=""
                  append-icon="mdi-camera"
                ></v-file-input>
              </v-col>

              <v-col
                cols="12"
                md="4"
              >
                <v-select
                  dense
                  hide-details
                  outlined
                  v-model="category.status"
                  :items="listStatuses"
                  :label="$t('status_*')"
                  :rules="[rules.required]"
                ></v-select>
              </v-col>

              <v-col
                cols="12"
                md="4"
                v-if="isNew || isCodeNull"
              >
                <v-text-field
                  dense
                  hide-details
                  outlined
                  :label="$t('code_*')"
                  v-mask="codeMask"
                  v-model="category.code"
                  :placeholder="codeMask"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  dense
                  outlined
                  :label="$t('hub_*')"
                  v-model="category.hub_ids"
                  :items="listHubs"
                  item-text="name"
                  item-value="id"
                  multiple
                  chips
                  :rules="[rules.required]"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <small>{{ $t("indicates_required_field") }}</small>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="closeForm()"
          >
            {{ $t("close") }}
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="save(category)"
            :disabled="!is_valid_form"
          >
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

import { validationRules } from "@/mixins/validationRules";
import { helpers } from "@/mixins/helpers";

export default {
  mixins: [validationRules, helpers],

  computed: {
    ...mapGetters({
      isLoadingCategories: "specialCategories/isLoadingCategories",
      rootCategories: "specialCategories/listRoots",
      shelfCategories: "specialCategories/listShelves",
      category: "specialCategories/category",
      categoryOld: "specialCategories/categoryOld",

      listStatuses: "statuses/listStatuses",
      listHubs: "hubs/activeHubs",
    }),

    isVisibleDialog: {
      get() {
        return this.$store.state.specialCategories.isVisibleDialog;
      },
      set(value) {
        this.$store.commit("specialCategories/IS_VISIBLE_DIALOG", value);
      },
    },

    codeMask() {
      if (2 == this.category.level) {
        return "###";
      }
      return "##";
    },

    isCodeNull() {
      return null == this.categoryOld.code;
    },

    isNew() {
      return !!this.categoryOld.id == false;
    },
  },

  watch: {
    isVisibleDialog: function (value) {
      if (value == true) {
        this.$store.dispatch("hubs/list", { for: "category" });
      }
    },

    // search_hub_input: function (search) {
    //     if (this.is_loading_hubs) return
    //     if(search === null) return
    //     this.$store.dispatch('hubs/list', {
    //         itemsPerPage: 50,
    //         for:'category',
    //         search,
    //     })
    // }
  },

  data() {
    return {
      is_valid_form: true,
      formErrors: [],

      is_loading_hubs: false,
      search_hub_input: null,

      categoriesType: [
        { level: 2, name: this.$t("category") },
        { level: 3, name: this.$t("subCategory") },
      ],
    };
  },
  methods: {
    async save(item) {
      if (this.$refs.form.validate()) {
        delete item.productsOfSpecial;
        delete item.parent;
        delete item.children;

        try {
          if (item.id) {
            await this.$store.dispatch("specialCategories/update", item);
            this.$store.dispatch(
              "alerts/success",
              this.$t("updated_successfully")
            );
          } else {
            await this.$store.dispatch("specialCategories/add", item);
            this.$store.dispatch(
              "alerts/success",
              this.$t("created_successfully")
            );
          }
          this.$refs.form.reset();
          this.closeForm();
          await this.$store.dispatch("specialCategories/listRoots");
        } catch (error) {
          console.log(error);
          this.$store.dispatch("alerts/error", error.response?.data?.message);
          this.formErrors = error.response.data.errors;
        }
      }
    },

    closeForm() {
      this.$refs.form.reset();
      this.$store.dispatch("specialCategories/closeForm");
      this.formErrors = [];
    },

    onFileChanged(e) {
      this.category.image_file = e;
    },
  },
};
</script>
<style>
.v-dialog {
  min-width: 800px;
}
</style>
